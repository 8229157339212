<template>
  <section class="el-login-validate-router">
    <div class="main">
      <div class="logo">
        <img src="@/assets/logo.png" alt="logo" />
      </div>
      <div class="title">江西省人民陪审员选任系统</div>
      <el-form :model="form.data" :rules="form.rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="username">
          <el-input type="test" v-model="form.data.username" disabled placeholder="请输入用户名" clearable maxlength="100">
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input type="phone" v-model="form.data.phone" disabled placeholder="请输入手机号" clearable maxlength="100">
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-phone"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="flex-btn">
            <el-input type="code" v-model="form.data.code" placeholder="请输入验证码" clearable maxlength="100">
              <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-lock2"></i>
            </el-input>
            <el-button
              class="getcode"
              :class="getcodeLoading?disabled?'wait codeloading':'codeloading':disabled?'wait':''"
              type="primary"
              :disabled="disabled"
              @click="requestSms"
            >
              <i class="el-icon-loading" v-if="getcodeLoading"></i>
              {{getcodeText}} {{timeout?timeout:''}}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button :type="'primary'" @click="submitForm('ruleForm')" :loading="submitLoading">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
// import router from '@/router';
import Login from '@/api/login';
export default {
  name: 'login',
  props: {},
  components: {},
  data() {
    return {
      form: {
        data: {
          username: '',
          code: '',
          phone: '',
        },
        rules: {
          code: [{ required: true, message: '请输入验证码', trigger: 'change' }],
        },
      },
      getcodeLoading: false,
      submitLoading: false,
      havecode: false,
      getcodeText: '获取验证码',
      timeout: '',
      timeoutCancle: '',
      disabled: false,
    };
  },
  methods: {
    submitForm(formName) {
      if (!this.havecode) {
        this.$message.closeAll();
        this.$message.error('请先获取验证码');
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          Login.verifySms({ code: this.form.data.code })
            .then(() => {
              clearInterval(this.timeoutCancle);
              this.$myset('loginLogo', true);
              this.$store.commit('setLoginLogo', true);
              this.$message.closeAll();
              this.$message({
                type: 'success',
                message: '登录成功',
                duration: 3000,
                showClose: true,
              });
              this.$store.dispatch('setDic', true);
            })
            .finally(() => {
              this.submitLoading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    requestSms() {
      this.getcodeLoading = true;
      Login.requestSms()
        .then(() => {
          this.$message.closeAll();
          this.$message.success('验证码已经发送，请查看手机');
          this.timeout = 60;
          this.getcodeText = '重新获取验证码';
          this.disabled = true;
          this.timeoutCancle = setInterval(() => {
            this.timeout--;
            if (this.timeout === 0) {
              this.disabled = false;
              this.timeout = '';
              clearInterval(this.timeoutCancle);
            }
          }, 1000);
          this.havecode = true;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.getcodeLoading = false;
        });
    },
    enter(e) {
      if (e.key === 'Enter') {
        return this.submitForm('ruleForm');
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.enter);
  },
  async created() {
    // 获取手机号与用户名，手机号换成*
    let {
      user: { phone, username },
    } = this.$myget('userInfo');
    this.form.data.username = username;
    if (phone.length > 4) {
      for (let i = 0; i < phone.length - 4; i++) {
        phone = phone.replace(phone[i], '*');
      }
    }
    this.form.data.phone = phone;

    // 仅仅用于简便
    this.requestSms();
    this.form.data.code = '0000';
  },
  computed: {},
  watch: {},
  beforeDestroy() {
    window.removeEventListener('keydown', this.enter);
  },
};
</script>

<style scoped lang="scss">
.el-login-validate-router {
  background: repeating-linear-gradient(90deg, #4859fe, #0acefe);
  display: flex;
  height: 100vh;
  .main {
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    width: 420px;
    height: 580px;
    padding: 23px 40px 0 40px;
    .flex-btn {
      display: flex;
      .getcode {
        flex: 2;
        display: block;
        position: relative;
      }
      .codeloading {
        opacity: 0.5;
      }
      .el-input {
        flex: 5;
      }
      .wait {
        opacity: 0.5;
      }
    }
    .title {
      color: rgb(0, 121, 254);
      font-size: 28px;
      font-weight: bold;
      padding-bottom: 22px;
    }
    .logo {
      padding-top: 55px;
      padding-bottom: 20px;
      img {
        display: inline-block;
        width: 100px;
        height: 100px;
      }
    }
    .el-button {
      background-color: #0079fe;
      width: 100%;
    }
    .el-button:hover {
      opacity: 0.7;
    }
    .el-button:active {
      opacity: 1;
    }
    ::v-deep .el-input__inner {
      height: 40px;
      line-height: 40px;
      text-indent: 10px;
    }
    ::v-deep .el-input-group__append {
      font-weight: 400;
      height: 40px;
      line-height: 40px;
      padding: 0;
    }
    .el-input__icon2 {
      height: 40px;
      line-height: 40px;
      font-size: 25px;
      padding-left: 5px;
    }
  }
}
</style>
